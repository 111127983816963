import {
	disableVisualizations,
	enableVisualizations,
	disableApplyRanges,
	enableApplyRanges,
	disableExportables,
	enableExportables,
	disableShowIntervals,
	enableShowIntervals
} from '@/api/pmvisualizations';

const disableVisualization = {
	id: 'disableVisualization',
	selectionType: 'multiple',
	label: 'pmvisualizations.actions.disableVisualization',
	functionality: 'UPDATE_PMVISUALIZATIONS',
	checkAvailability: function (visualization) {
		return visualization && visualization.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmvisualizations.actions.disableVisualization');
		let allowDisable = true;
		var body = [];
		for (let i = 0; i < registries.length; i++) {
			if (registries[i].linkedparameters > 0) {
				allowDisable = false;
			} else {
				body.push({
					pmvisualizationsid: registries[i].pmvisualizationsid
				});
			}
		}

		let data;
		if (allowDisable) {
			data = await disableVisualizations(body);
		} else {
			data = { error: true };
		}
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = allowDisable ? this.$puiI18n.t('puiaction.notifyError') : this.$t('pmvisualizations.actions.disableError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVisualization = {
	id: 'enableVisualization',
	selectionType: 'multiple',
	label: 'pmvisualizations.actions.enableVisualization',
	functionality: 'UPDATE_PMVISUALIZATIONS',
	checkAvailability: function (visualization) {
		return visualization && visualization.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmvisualizations.actions.enableVisualization');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmvisualizationsid: registries[i].pmvisualizationsid
			});
		}

		const data = await enableVisualizations(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const disableApplyRange = {
	id: 'disableApplyRange',
	selectionType: 'multiple',
	label: 'pmvisualizations.actions.disableApplyRange',
	functionality: 'UPDATE_PMVISUALIZATIONS',
	checkAvailability: function (visualization) {
		return visualization && visualization.applyranges === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmvisualizations.actions.disableApplyRange');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmvisualizationsid: registries[i].pmvisualizationsid
			});
		}

		const data = await disableApplyRanges(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableApplyRange = {
	id: 'enableApplyRange',
	selectionType: 'multiple',
	label: 'pmvisualizations.actions.enableApplyRange',
	functionality: 'UPDATE_PMVISUALIZATIONS',
	checkAvailability: function (visualization) {
		return visualization && visualization.applyranges === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmvisualizations.actions.enableApplyRange');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmvisualizationsid: registries[i].pmvisualizationsid
			});
		}

		const data = await enableApplyRanges(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const disableExportable = {
	id: 'disableExportable',
	selectionType: 'multiple',
	label: 'pmvisualizations.actions.disableExportable',
	functionality: 'UPDATE_PMVISUALIZATIONS',
	checkAvailability: function (visualization) {
		return visualization && visualization.exportable === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmvisualizations.actions.disableExportable');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmvisualizationsid: registries[i].pmvisualizationsid
			});
		}

		const data = await disableExportables(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableExportable = {
	id: 'enableExportable',
	selectionType: 'multiple',
	label: 'pmvisualizations.actions.enableExportable',
	functionality: 'UPDATE_PMVISUALIZATIONS',
	checkAvailability: function (visualization) {
		return visualization && visualization.exportable === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmvisualizations.actions.enableExportable');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmvisualizationsid: registries[i].pmvisualizationsid
			});
		}

		const data = await enableExportables(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const disableShowInterval = {
	id: 'disableShowInterval',
	selectionType: 'multiple',
	label: 'pmvisualizations.actions.disableShowInterval',
	functionality: 'UPDATE_PMVISUALIZATIONS',
	checkAvailability: function (visualization) {
		return visualization && visualization.showintervals === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmvisualizations.actions.disableShowInterval');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmvisualizationsid: registries[i].pmvisualizationsid
			});
		}

		const data = await disableShowIntervals(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableShowInterval = {
	id: 'enableShowInterval',
	selectionType: 'multiple',
	label: 'pmvisualizations.actions.enableShowInterval',
	functionality: 'UPDATE_PMVISUALIZATIONS',
	checkAvailability: function (visualization) {
		return visualization && visualization.showintervals === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmvisualizations.actions.enableShowInterval');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmvisualizationsid: registries[i].pmvisualizationsid
			});
		}

		const data = await enableShowIntervals(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [
		disableVisualization,
		enableVisualization,
		disableApplyRange,
		enableApplyRange,
		disableExportable,
		enableExportable,
		disableShowInterval,
		enableShowInterval
	]
};
