// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtype
import Pui9HttpRequests from '@Pui9Requests';

export async function disableVisualizations(visualization) {
	const controller = '/pmvisualizations/disableVisualizations';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		visualization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableVisualizations(visualization) {
	const controller = '/pmvisualizations/enableVisualizations';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		visualization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function disableApplyRanges(visualization) {
	const controller = '/pmvisualizations/disableApplyRanges';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		visualization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableApplyRanges(visualization) {
	const controller = '/pmvisualizations/enableApplyRanges';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		visualization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function disableExportables(visualization) {
	const controller = '/pmvisualizations/disableExportables';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		visualization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableExportables(visualization) {
	const controller = '/pmvisualizations/enableExportables';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		visualization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function disableShowIntervals(visualization) {
	const controller = '/pmvisualizations/disableShowIntervals';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		visualization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableShowIntervals(visualization) {
	const controller = '/pmvisualizations/enableShowIntervals';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		visualization,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
